<template>
  <div class="fault-content">
    <div class="display-flex">
      <span class="text-s">站点名称</span>
      <el-select v-model="value" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span class="text-s">故障总数： {{ num }}个</span>
      <div class="weather">
        <span class="text"
          >天气信息&nbsp;&nbsp;阴天&nbsp;&nbsp;（30~35）℃&nbsp;&nbsp;&nbsp;&nbsp;东南风&nbsp;&nbsp;5级&nbsp;&nbsp;相对湿度（50~55）%</span
        >
      </div>
      <span class="text-s">{{ times }}</span>
    </div>
    <div class="content-1">
      <div class="top display-flex">
        <div class="left">
          <div class="display-flex texts">PRPD放电故障统计</div>
          <div class="display-flex textsub">设备类型统计</div>
          <div class="chart">
            <chart
              :cdata="prpd_bar_options"
              :id="'prpd_bar'"
              :height="'calc(14vh)'"
            />
          </div>
          <div class="display-flex textsub">故障类型统计</div>
          <div class="chart">
            <chart
              :cdata="prpd_pie_options"
              :id="'prpd_pie'"
              :height="'calc(14vh)'"
            />
          </div>
        </div>
        <div class="center">
          <div class="display-flex texts">振动监测故障统计</div>
          <div class="display-flex textsub">设备类型统计</div>
          <div class="chart">
            <chart
              :cdata="zd_bar_options"
              :id="'zd_bar'"
              :height="'calc(14vh)'"
            />
          </div>
          <div class="display-flex textsub">故障类型统计</div>
          <div class="chart">
            <chart
              :cdata="zd_pie_options"
              :id="'zd_pie'"
              :height="'calc(14vh)'"
            />
          </div>
        </div>
        <div class="right">
          <div class="display-flex texts">温度监测故障统计</div>
          <div class="display-flex textsub">设备类型统计</div>
          <div class="chart">
            <chart
              :cdata="wd_bar_options"
              :id="'wd_bar'"
              :height="'calc(14vh)'"
            />
          </div>
          <div class="display-flex textsub">故障类型统计</div>
          <div class="chart">
            <chart
              :cdata="wd_pie_options"
              :id="'wd_pie'"
              :height="'calc(14vh)'"
            />
          </div>
        </div>
      </div>
      <div class="bottom display-flex">
        <div class="left">
          <div class="display-flex texts">故障列表</div>
          <div class="display-flex">
            <span class="text-s">查询日期</span>
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span class="text-s">事件类型</span>
            <el-select v-model="shijian" placeholder="请选择">
              <el-option
                v-for="item in typeoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-button
              style="height: 40px; margin-top: 10px; margin-left: 10px"
              type="primary"
              >搜索</el-button
            >
          </div>
          <div class="table">
            <el-table
              :data="tableData"
              height="calc(50vh - 250px)"
              border
              style="width: 98%; margin-left: 1%"
              @row-click="goToMassage"
              :row-class-name="tableRowClassName"
            >
              <el-table-column
                v-for="(item, index) in tableTitleList"
                :width="item.width"
                :label="item.name"
                :key="index"
                :prop="item.prop"
                :sortable="item.sortable"
                align="center"
              ></el-table-column>
            </el-table>
          </div>
          <div class="display-flex pages">共{{ tableData.length }}条</div>
        </div>
        <div class="right">
          <div class="display-flex texts">故障事件数（一个月）</div>
          <div class="chart">
            <chart
              :cdata="lineChartOptions"
              :id="'line_charts'"
              :height="'calc(50vh - 170px)'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chart from "./echarts.vue";
export default {
  components: { chart },
  data() {
    return {
      num: 64,
      times: "", //格式化之后的当前时间
      options: [
        {
          value: "1",
          label: "站点1",
        },
        {
          value: "2",
          label: "站点2",
        },
        {
          value: "3",
          label: "站点3",
        },
        {
          value: "4",
          label: "站点4",
        },
        {
          value: "5",
          label: "站点5",
        },
      ],
      typeoptions: [
        {
          value: "1",
          label: "温度过热",
        },
        {
          value: "2",
          label: "局放prpd",
        },
        {
          value: "3",
          label: "振动异常",
        },
      ],
      shijian: "1",
      selectIndex: "",
      tableData: [
        {
          date: "2016-05-01",
          name: "xx号变压器1",
          level: "35kV",
          max: "200",
          average: "100",
          pulse: "xxx",
          pos: "温度过热",
          tem: "未处理",
          prpdid: "100008923",
        },
        {
          date: "2016-05-02",
          name: "xx号变压器10",
          level: "35kV",
          max: "200",
          average: "100",
          pulse: "xxx",
          pos: "PRPD-尖端放电",
          tem: "未处理",
          prpdid: "100008923",
        },
        {
          date: "2016-05-03",
          name: "xx号变压器2",
          level: "35kV",
          max: "200",
          average: "100",
          pulse: "xxx",
          pos: "振动振幅过大",
          tem: "已处理",
          prpdid: "100008923",
        },
        {
          date: "2016-05-04",
          name: "xx号变压器2",
          level: "35kV",
          max: "200",
          average: "100",
          pulse: "xxx",
          pos: "PRPD-沿面放电",
          tem: "已处理",
          prpdid: "100008923",
        },
        {
          date: "2016-05-05",
          name: "xx号变压器3",
          level: "35kV",
          max: "200",
          average: "100",
          pulse: "xxx",
          pos: "xxx",
          tem: "已处理",
          prpdid: "100008923",
        },
        {
          date: "2016-05-06",
          name: "xx号变压器3",
          level: "35kV",
          max: "200",
          average: "100",
          pulse: "xxx",
          pos: "xxx",
          tem: "已处理",
          prpdid: "100008923",
        },
        {
          date: "2016-05-07",
          name: "xx号变压器4",
          level: "35kV",
          max: "200",
          average: "100",
          pulse: "xxx",
          pos: "xxx",
          tem: "已处理",
          prpdid: "100008923",
        },
      ],
      value: "1",
      prpd_bar_options: {},
      prpd_pie_options: {},
      zd_bar_options: {},
      zd_pie_options: {},
      wd_bar_options: {},
      wd_pie_options: {},
      lineChartOptions: {},
      tableTitleList: [
        { name: "序号", prop: "index", width: 50, sortable: false },
        { name: "设备名称", prop: "name", sortable: false },
        { name: "故障发生时间", prop: "date", sortable: true },
        { name: "处理情况", prop: "tem", sortable: true },
        { name: "事件类型", prop: "pos", sortable: true },
        { name: "故障描述", prop: "pulse", sortable: true }
      ],
    };
  },
  created() {
    this.getTimes();
    this.setData();
  },
  methods: {
    getTimes() {
      setInterval(this.getTimesInterval, 1000);
    },
    getTimesInterval: function () {
      let _this = this;
      let year = new Date().getFullYear(); //获取当前时间的年份
      let month = new Date().getMonth() + 1; //获取当前时间的月份
      let day = new Date().getDate(); //获取当前时间的天数
      let hours = new Date().getHours(); //获取当前时间的小时
      let minutes = new Date().getMinutes(); //获取当前时间的分数
      let seconds = new Date().getSeconds(); //获取当前时间的秒数
      //当小于 10 的是时候，在前面加 0
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      //拼接格式化当前时间
      _this.times =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;
    },
    setData() {
      this.setPrpdBarOptions();
      this.setPrpdPieOptions();
      this.setZdBarOptions();
      this.setZdPieOptions();
      this.setWdBarOptions();
      this.setWdPieOptions();
      this.setLineChart();
    },
    setPrpdBarOptions() {
      let data1 = [
        {
          name: "变压器",
          value: 10,
        },
        {
          name: "开关柜",
          value: 10,
        },
        {
          name: "电缆",
          value: 10,
        },
      ];
      let xAxisData = [];
      let seriesData1 = [];
      let barTopColor = [
        "#ff0010",
        "#ff2000",
        "#ff0010",
        "#ff2000",
        "#ff0010",
        "#ff2000",
        "#ff0010",
        "#ff2000",
      ];
      let barBottomColor = [
        "rgba(2,195,241,0.1)",
        "rgba(83, 229, 104, 0.1)",

        "rgba(2,195,241,0.1)",
        "rgba(83, 229, 104, 0.1)",

        "rgba(2,195,241,0.1)",
        "rgba(83, 229, 104, 0.1)",

        "rgba(2,195,241,0.1)",
        "rgba(83, 229, 104, 0.1)",
      ];
      data1.forEach((item) => {
        xAxisData.push(item.name);
        seriesData1.push(item.value);
      });
      this.prpd_bar_options = {
        grid: {
          top: "30%",
          bottom: "40%",
          left: "50",
          right: "100",
          containLabel: false,
        },
        xAxis: {
          data: xAxisData,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            margin: 25,
            rotate: 25,
            align: "center",
            textStyle: {
              fontSize: 12,
              color: "#ffffff",
            },
          },
          interval: 0,
        },
        yAxis: {
          type: "value",
          // name: '件',
          nameTextStyle: {
            color: "#FFFFFF",
            fontSize: 12,
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
        },
        series: [
          {
            name: "柱顶部",
            type: "pictorialBar",
            symbolSize: [46, 10],
            symbolOffset: [0, -5],
            z: 12,
            itemStyle: {
              normal: {
                color: function (params) {
                  return barTopColor[params.dataIndex];
                },
              },
            },
            label: {
              show: true,
              position: "top",
              fontSize: 20,
              color: "#ffffff",
            },
            symbolPosition: "end",
            data: seriesData1,
          },
          {
            name: "柱底部",
            type: "pictorialBar",
            symbolSize: [46, 10],
            symbolOffset: [0, 5],
            z: 12,
            itemStyle: {
              normal: {
                color: function (params) {
                  return barTopColor[params.dataIndex];
                },
              },
            },
            data: seriesData1,
          },
          {
            type: "bar",
            itemStyle: {
              normal: {
                color: {
                  type: "linear",
                  x: 0, // 右
                  y: 0, // 下
                  x2: 1, // 左
                  y2: 0, // 上
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(83, 229, 104, 0.3)",
                    },
                    {
                      offset: 0.1,
                      color: "rgba(83, 229, 104, 0.1)",
                    },
                    {
                      offset: 0.8,
                      color: "rgba(27, 143, 25, 0.3)",
                    },
                    {
                      offset: 1,
                      color: "rgba(2,195,241,0.1)",
                    },
                  ],
                  global: false, // 缺省为 false
                },
                opacity: 0.8,
              },
            },
            z: 16,
            silent: true,
            barWidth: 46,
            barGap: "-100%", // Make series be overlap
            data: seriesData1,
          },
        ],
      };
    },
    setPrpdPieOptions() {
      var data = [
        {
          name: "沿面放电",
          value: 10,
        },
        {
          name: "悬浮放电",
          value: 10,
        },
        {
          name: "尖端放电",
          value: 10,
        },
      ];

      this.prpd_pie_options = {
        color: ["#ED1C24", "#B5E61D", "#FF7F27", "rgba(250,250,250,0.3)"],
        backgroundColor: "transparent",
        title: {
          text: "总数",
          subtext: 30,
          textStyle: {
            color: "#f2f2f2",
            fontSize: 24,
            // align: 'center'
          },
          x: "center",
          y: "center",
        },
        grid: {
          top: "1%",
          bottom: "1%",
          left: 100,
          right: "10%",
        },
        legend: {
          orient: "vertical",
          top: "middle",
          right: "5%",
          textStyle: {
            color: "#f2f2f2",
            fontSize: 14,
          },
          icon: "roundRect",
          data: data,
        },
        series: [
          // 主要展示层的
          {
            radius: ["50%", "100%"],
            center: ["50%", "50%"],
            type: "pie",
            label: {
              normal: {
                show: true,
                formatter: "{c}%",
                textStyle: {
                  fontSize: 18,
                },
                position: "inside",
              },
              emphasis: {
                show: true,
              },
            },
            labelLine: {
              normal: {
                show: true,
                length: 30,
                length2: 55,
              },
              emphasis: {
                show: true,
              },
            },
            name: "",
            data: data,
          },
        ],
      };
    },
    setZdBarOptions() {
      let data1 = [
        {
          name: "变压器",
          value: 12,
        },
        {
          name: "开关柜",
          value: 10,
        },
        {
          name: "电缆",
          value: 4,
        },
      ];
      let xAxisData = [];
      let seriesData1 = [];
      let barTopColor = [
        "#ff0010",
        "#ff2000",
        "#ff0010",
        "#ff2000",
        "#ff0010",
        "#ff2000",
        "#ff0010",
        "#ff2000",
      ];
      data1.forEach((item) => {
        xAxisData.push(item.name);
        seriesData1.push(item.value);
      });
      this.zd_bar_options = {
        grid: {
          top: "30%",
          bottom: "40%",
          left: "50",
          right: "100",
          containLabel: false,
        },
        xAxis: {
          data: xAxisData,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            margin: 25,
            rotate: 25,
            align: "center",
            textStyle: {
              fontSize: 12,
              color: "#ffffff",
            },
          },
          interval: 0,
        },
        yAxis: {
          type: "value",
          // name: '件',
          nameTextStyle: {
            color: "#FFFFFF",
            fontSize: 12,
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
        },
        series: [
          {
            name: "柱顶部",
            type: "pictorialBar",
            symbolSize: [46, 10],
            symbolOffset: [0, -5],
            z: 12,
            itemStyle: {
              normal: {
                color: function (params) {
                  return barTopColor[params.dataIndex];
                },
              },
            },
            label: {
              show: true,
              position: "top",
              fontSize: 20,
              color: "#ffffff",
            },
            symbolPosition: "end",
            data: seriesData1,
          },
          {
            name: "柱底部",
            type: "pictorialBar",
            symbolSize: [46, 10],
            symbolOffset: [0, 5],
            z: 12,
            itemStyle: {
              normal: {
                color: function (params) {
                  return barTopColor[params.dataIndex];
                },
              },
            },
            data: seriesData1,
          },
          {
            type: "bar",
            itemStyle: {
              normal: {
                color: {
                  type: "linear",
                  x: 0, // 右
                  y: 0, // 下
                  x2: 1, // 左
                  y2: 0, // 上
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(83, 229, 104, 0.3)",
                    },
                    {
                      offset: 0.1,
                      color: "rgba(83, 229, 104, 0.1)",
                    },
                    {
                      offset: 0.8,
                      color: "rgba(27, 143, 25, 0.3)",
                    },
                    {
                      offset: 1,
                      color: "rgba(2,195,241,0.1)",
                    },
                  ],
                  global: false, // 缺省为 false
                },

                opacity: 0.8,
              },
            },
            z: 16,
            silent: true,
            barWidth: 46,
            barGap: "-100%", // Make series be overlap
            data: seriesData1,
          },
        ],
      };
    },
    setZdPieOptions() {
      var data = [
        {
          name: "不平衡",
          value: 10,
        },
        {
          name: "不对中",
          value: 8,
        },
        {
          name: "偏心转子",
          value: 8,
        },
      ];

      this.zd_pie_options = {
        color: ["#ED1C24", "#B5E61D", "#FF7F27", "rgba(250,250,250,0.3)"],
        backgroundColor: "transparent",
        title: {
          text: "总数",
          subtext: 26,
          textStyle: {
            color: "#f2f2f2",
            fontSize: 24,
            // align: 'center'
          },
          subtextStyle: {
            fontSize: 18,
            color: ["#000"],
          },
          x: "center",
          y: "center",
        },
        grid: {
          bottom: 0,
          left: 100,
          right: "10%",
        },
        legend: {
          orient: "vertical",
          top: "middle",
          right: "5%",
          textStyle: {
            color: "#f2f2f2",
            fontSize: 14,
          },
          icon: "roundRect",
          data: data,
        },
        series: [
          // 主要展示层的
          {
            radius: ["50%", "100%"],
            center: ["50%", "50%"],
            type: "pie",
            label: {
              normal: {
                show: true,
                formatter: "{c}%",
                textStyle: {
                  fontSize: 14,
                },
                position: "inside",
              },
              emphasis: {
                show: true,
              },
            },
            labelLine: {
              normal: {
                show: true,
                length: 30,
                length2: 55,
              },
              emphasis: {
                show: true,
              },
            },
            name: "",
            data: data,
          },
        ],
      };
    },
    setWdBarOptions() {
      let data1 = [
        {
          name: "变压器",
          value: 5,
        },
        {
          name: "开关柜",
          value: 4,
        },
        {
          name: "电缆",
          value: 2,
        },
      ];
      let xAxisData = [];
      let seriesData1 = [];
      let barTopColor = [
        "#ff0010",
        "#ff2000",
        "#ff0010",
        "#ff2000",
        "#ff0010",
        "#ff2000",
        "#ff0010",
        "#ff2000",
      ];
      let barBottomColor = [
        "rgba(2,195,241,0.1)",
        "rgba(83, 229, 104, 0.1)",

        "rgba(2,195,241,0.1)",
        "rgba(83, 229, 104, 0.1)",

        "rgba(2,195,241,0.1)",
        "rgba(83, 229, 104, 0.1)",

        "rgba(2,195,241,0.1)",
        "rgba(83, 229, 104, 0.1)",
      ];
      data1.forEach((item) => {
        xAxisData.push(item.name);
        seriesData1.push(item.value);
      });
      this.wd_bar_options = {
        grid: {
          top: "30%",
          bottom: "40%",
          left: "50",
          right: "100",
          containLabel: false,
        },
        xAxis: {
          data: xAxisData,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            margin: 25,
            rotate: 25,
            align: "center",
            textStyle: {
              fontSize: 12,
              color: "#ffffff",
            },
          },
          interval: 0,
        },
        yAxis: {
          type: "value",
          // name: '件',
          nameTextStyle: {
            color: "#FFFFFF",
            fontSize: 12,
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
        },
        series: [
          {
            name: "柱顶部",
            type: "pictorialBar",
            symbolSize: [46, 10],
            symbolOffset: [0, -5],
            z: 12,
            itemStyle: {
              normal: {
                color: function (params) {
                  return barTopColor[params.dataIndex];
                },
              },
            },
            label: {
              show: true,
              position: "top",
              fontSize: 20,
              color: "#ffffff",
            },
            symbolPosition: "end",
            data: seriesData1,
          },
          {
            name: "柱底部",
            type: "pictorialBar",
            symbolSize: [46, 10],
            symbolOffset: [0, 5],
            z: 12,
            itemStyle: {
              normal: {
                color: function (params) {
                  return barTopColor[params.dataIndex];
                },
              },
            },
            data: seriesData1,
          },
          {
            type: "bar",
            itemStyle: {
              normal: {
                color: {
                  type: "linear",
                  x: 0, // 右
                  y: 0, // 下
                  x2: 1, // 左
                  y2: 0, // 上
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(83, 229, 104, 0.3)",
                    },
                    {
                      offset: 0.1,
                      color: "rgba(83, 229, 104, 0.1)",
                    },
                    {
                      offset: 0.8,
                      color: "rgba(27, 143, 25, 0.3)",
                    },
                    {
                      offset: 1,
                      color: "rgba(2,195,241,0.1)",
                    },
                  ],
                  global: false, // 缺省为 false
                },
                opacity: 0.8,
              },
            },
            z: 16,
            silent: true,
            barWidth: 46,
            barGap: "-100%", // Make series be overlap
            data: seriesData1,
          },
        ],
      };
    },
    setWdPieOptions() {
      var data = [
        {
          name: "温度过高",
          value: 1,
        },
        {
          name: "温度高",
          value: 10,
        },
      ];

      this.wd_pie_options = {
        color: ["#ED1C24", "#FF7F27", "rgba(250,250,250,0.3)"],
        backgroundColor: "transparent",
        title: {
          text: "总数",
          subtext: 11,
          textStyle: {
            color: "#f2f2f2",
            fontSize: 24,
          },
          subtextStyle: {
            fontSize: 18,
            color: ["#fff200"],
          },
          x: "center",
          y: "center",
        },
        grid: {
          bottom: 150,
          left: 100,
          right: "10%",
        },
        legend: {
          orient: "vertical",
          top: "middle",
          right: "5%",
          textStyle: {
            color: "#f2f2f2",
            fontSize: 14,
          },
          icon: "roundRect",
          data: data,
        },
        series: [
          // 主要展示层的
          {
            radius: ["50%", "100%"],
            center: ["50%", "50%"],
            type: "pie",
            label: {
              normal: {
                show: true,
                formatter: "{c}%",
                textStyle: {
                  fontSize: 14,
                },
                position: "inside",
              },
              emphasis: {
                show: true,
              },
            },
            labelLine: {
              normal: {
                show: true,
                length: 30,
                length2: 55,
              },
              emphasis: {
                show: true,
              },
            },
            name: "民警训练总量",
            data: data,
          },
        ],
      };
    },
    setLineChart() {
      /**
       * 图标数据
       */
      var data = {
        id: "multipleBarsLines",
        title: "",
        legendBar: ["振动事件总数", "局放事件总数", "温度事件总数"],
        symbol: "%", //数值是否带百分号        --默认为空 ''
        legendLine: ["事件总数"],
        xAxis: ["9-10", "9-12", "9-13", "9-14", "9-15", "9-16", "9-17"],
        yAxis: [
          [8, 10, 10, 11, 4, 13, 6, 5, 9, 7],
          [10, 7, 8, 8, 7, 9, 8, 7, 3, 5],
          [6, 5, 4, 7, 9, 4, 5, 3, 7, 11],
        ],
        lines: [[24, 22, 22, 26, 20, 26, 19, 15, 19, 23]],
        barColor: ["#3FA7DC", "#7091C4", "#5170A2"], //柱子颜色 必填参数
        lineColor: ["#DA8084"], // 折线颜色
      };
      /////////////end/////////

      var myData = (function test() {
        let yAxis = data.yAxis || [];
        let lines = data.lines || [];
        let legendBar = data.legendBar || [];
        let legendLine = data.legendLine || [];
        var symbol = data.symbol || " ";
        let seriesArr = [];
        let legendArr = [];
        yAxis &&
          yAxis.forEach((item, index) => {
            legendArr.push({
              name: legendBar && legendBar.length > 0 && legendBar[index],
            });
            seriesArr.push({
              name: legendBar && legendBar.length > 0 && legendBar[index],
              type: "bar",
              barGap: "0.5px",
              data: item,
              barWidth: data.barWidth || 12,
              label: {
                normal: {
                  show: true,
                  formatter: "{c}",
                  position: "top",
                  textStyle: {
                    color: "#fff",
                    fontStyle: "normal",
                    fontFamily: "微软雅黑",
                    textAlign: "left",
                    fontSize: 18,
                  },
                },
              },
              itemStyle: {
                //图形样式
                normal: {
                  barBorderRadius: 4,
                  color: data.barColor[index],
                },
              },
            });
          });

        lines &&
          lines.forEach((item, index) => {
            legendArr.push({
              name: legendLine && legendLine.length > 0 && legendLine[index],
            });
            seriesArr.push({
              name: legendLine && legendLine.length > 0 && legendLine[index],
              type: "line",
              data: item,
              itemStyle: {
                normal: {
                  color: data.lineColor[index],
                  lineStyle: {
                    width: 3,
                    type: "solid",
                  },
                },
              },
              label: {
                normal: {
                  show: true, //折线上方label控制显示隐藏
                  position: "top",
                  textStyle: {
                    color: "#fff",
                    fontStyle: "normal",
                    fontFamily: "微软雅黑",
                    textAlign: "left",
                    fontSize: 18,
                  },
                },
              },
              symbol: "circle",
              symbolSize: 10,
            });
          });

        return {
          seriesArr,
          legendArr,
        };
      })();

      this.lineChartOptions = {
        backgroundColor: "transparent",
        title: {
          show: true,
          text: data.title,
          subtext: data.subTitle,
          link: "http://gallery.echartsjs.com/editor.html?c=xB1j9UgsXQ",
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            var time = "";
            var str = "";
            for (var i of params) {
              time = i.name.replace(/\n/g, "") + "<br/>";
              if (i.data == "null" || i.data == null) {
                str += i.seriesName + "：无数据" + "<br/>";
              } else {
                str += i.seriesName + "：" + i.data + "%<br/>";
              }
            }
            return time + str;
          },
          axisPointer: {
            type: "none",
          },
        },
        legend: {
          right: data.legendRight || "30%",
          top: 32,
          itemGap: 16,
          itemWidth: 10,
          itemHeight: 10,
          data: myData.legendArr,
          textStyle: {
            color: "#fff",
            fontStyle: "normal",
            fontFamily: "微软雅黑",
            fontSize: 18,
          },
        },
        grid: {
          x: 30,
          y: 80,
          x2: 30,
          y2: 60,
        },
        xAxis: {
          type: "category",
          data: data.xAxis,
          axisTick: {
            show: false,
          },

          axisLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            interval: "0",
            textStyle: {
              lineHeight: 16,
              padding: [2, 2, 0, 2],
              height: 50,
              fontSize: 16,
            },
            rich: {
              Sunny: {
                height: 50,
                // width: 60,
                padding: [0, 5, 0, 5],
                align: "center",
              },
            },
            formatter: function (params, index) {
              var newParamsName = "";
              var splitNumber = 5;
              var paramsNameNumber = params && params.length;
              if (paramsNameNumber && paramsNameNumber <= 4) {
                splitNumber = 4;
              } else if (paramsNameNumber >= 5 && paramsNameNumber <= 7) {
                splitNumber = 4;
              } else if (paramsNameNumber >= 8 && paramsNameNumber <= 9) {
                splitNumber = 5;
              } else if (paramsNameNumber >= 10 && paramsNameNumber <= 14) {
                splitNumber = 5;
              } else {
                params = params && params.slice(0, 15);
              }

              var provideNumber = splitNumber; //一行显示几个字
              var rowNumber = Math.ceil(paramsNameNumber / provideNumber) || 0;
              if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  var start = p * provideNumber;
                  var end = start + provideNumber;
                  if (p == rowNumber - 1) {
                    tempStr = params.substring(start, paramsNameNumber);
                  } else {
                    tempStr = params.substring(start, end) + "\n";
                  }
                  newParamsName += tempStr;
                }
              } else {
                newParamsName = params;
              }
              params = newParamsName;
              return "{Sunny|" + params + "}";
            },
            color: "#fff",
          },
        },
        yAxis: {
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#fff",
              type: "solid",
            },
            interval: 2,
          },
          splitNumber: 4,
        },
        series: myData.seriesArr,
      };
    },
    goToMassage(row, event, column) {
      // 在这里处理行点击事件
      console.log("行点击事件：", row, event, column);
      this.$router.push("/faultDiag/second");
      this.selectIndex = row.index;
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
      if (rowIndex == this.selectIndex) {
        return "success-row";
      }
    },
  },
  beforeDestroy() {
    if (this.times) {
      clearInterval(this.getTimesInterval);
    }
  },
};
</script>

<style scoped lang="scss">
.fault-content {
  width: 95%;
  margin: 0 auto;
  height: calc(100vh - 160px);
  color: rgb(113, 203, 255);

  .display-flex {
    display: flex;
  }
  .text-s {
    font-size: 20px;
    color: rgb(194, 213, 238);
    font-weight: 700;
    margin: 5px 40px;
    line-height: 50px;
  }
  .weather {
    width: 46%;
    height: 50px;
    margin-top: 5px;
    border: 1px solid rgba(27, 217, 247, 1);
    .text {
      font-size: 24px;
      line-height: 45px;
      font-weight: 700;
      margin-left: 30px;
    }
  }
  .content-1 {
    height: calc(100vh - 200px);
    overflow-y: scroll;
    .top {
      width: 100%;
      height: 50%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      .chart {
        width: 100%;
        height: 40%;
      }
      .left {
        margin: 5px;
        border: 1px solid rgba(27, 217, 247, 1);
      }
      .center {
        margin: 5px;
        border: 1px solid rgba(27, 217, 247, 1);
      }
      .right {
        margin: 5px;
        border: 1px solid rgba(27, 217, 247, 1);
      }
      .texts {
        color: aliceblue;
        font-size: 22px;
      }
      .textsub {
        color: #cac220;
        font-size: 18px;
      }
    }
    .bottom {
      height: 48%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      .left {
        margin: 5px;
        border: 1px solid rgba(27, 217, 247, 1);
        .pages {
          font-size: 16px;
          color: #5fc5e4;
          float: right;
          margin-right: 40px;
        }
      }
      .right {
        margin: 5px;
        border: 1px solid rgba(27, 217, 247, 1);
        .chart {
          width: 100%;
          height: 90%;
        }
      }

      .texts {
        color: aliceblue;
        font-size: 22px;
      }
    }
  }

  .display-align {
    align-items: center;
  }

  .display-justify {
    justify-content: space-between;
  }
  /deep/ .el-select {
    margin-top: 10px;
  }
  /deep/ .el-table th {
    color: rgb(236, 227, 227);
    padding: 0 ;
    line-height: 1px;
    text-align: center;
    background-color: #339bcf50 !important;
  }
  /deep/ .el-table tr {
    padding: 0;
    height: 10px;
    background-color: transparent !important;
    text-align: center !important;
  }
  /deep/ .el-table__cell {
    padding: 0px 0px;
  }

  /deep/ .el-table--border,
  .el-table--group {
    border: 1px solid #555f64;
  }
  /deep/ .el-table {
    color: rgb(202, 224, 231);
    background-color: rgba(0, 60, 200, 1);
  }

  /deep/ .el-table--border::after,
  .el-table--group::after,
  .el-table::before {
    content: "";
    position: absolute;
    background-color: #174bdaa2;
    z-index: 1;
  }

  /deep/ .el-table--border th,
  .el-table__fixed-right-patch {
    border-bottom: 1px solid #dd7e11a2;
  }
  /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: rgba(139, 71, 6, 0.87); //颜色必须是rgb
  }
  // 必须加组件穿透和优先级最高
  /deep/ .el-table .success-row {
    background-color: rgba(139, 71, 6, 0.87) !important;
  }
}
</style>